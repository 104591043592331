<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="货源编号" prop="pno">
            <el-input v-model="conditions.pno" clearable filterable> </el-input>
          </el-form-item>
          <el-form-item label="货源状态" prop="status">
            <el-select
              v-model="conditions.status"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="(item, index) in statusEnum"
                :key="index"
                :value="index"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发布去向" prop="isopen">
            <el-select v-model="conditions.isopen" clearable>
              <el-option
                v-for="(item, index) in openEnum"
                :key="index"
                :value="index"
                :label="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              class="buttons"
              @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table border :data="tableList">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="发货公司"
            prop="sendOwner"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="发货联系人"
            prop="sendContacts"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="收货公司"
            prop="receiveOwner"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="收货联系人"
            prop="receiveContacts"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货物类别"
            prop="cargoType"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货物名称"
            prop="cargoTypeName"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货物重量"
            prop="plantons"
          >
            <template v-slot="scope">
              {{ scope.row.plantons + "吨" }}
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="运费单价"
            prop="plantons"
          >
            <template v-slot="scope">
              {{ scope.row.plantons + "元/吨" }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="货源编号" prop="pno">
          </el-table-column>
          <el-table-column show-overflow-tooltip label="货源状态" prop="">
            <template v-slot="scope">
              <el-tag
                size="small"
                :type="statusEnum[scope.row.status].type"
                v-if="statusEnum[scope.row.status]"
                >{{ statusEnum[scope.row.status].label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="发布去向" prop="">
            <template v-slot="scope">
              {{ openEnum[scope.row.isopen] || "" }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button size="mini" type="text" @click="goDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
  </div>
</template>
<script>
import lodash from "lodash";
import Pagination from "@/components/pagination.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      conditions: {
        page: 1,
        limit: 10,
        sendOwnerId: "",
        receiveOwnerId: "",
        status: ["11"],
        pno: "",
        isopen: "",
        gmtCreate: "",
      },
      statusEnum: {
        11: { label: "待审核", type: "warning" },
        12: { label: "驳回", type: "danger" },
        13: { label: "已发布", type: "primary" },
      },
      openEnum: {
        0: "货源大厅",
        1: "车队/司机",
      },
      ownerOptions: [],
      tableList: [],
      total: 0,
    };
  },
  // computed: {
  //   ...mapState(["conditionsList"]),
  // },
  components: {
    Pagination,
  },
  computed: {
    ...mapState(["pendingCount"]),
  },
  created() {
    this.getList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getPendingCount();
    });
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    // 获取列表数据
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/PlanInfo/auditList",
        "get",
        this.transformConditions()
      );
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
    },
    transformConditions() {
      let clone = lodash.cloneDeep(this.conditions);
      if (clone.status.length == 0) {
        clone.status = "11,12,13";
      } else {
        clone.status = clone.status.toString();
      }
      return clone;
    },
    // 获取货场列表
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    goDetail(row) {
      this.$router.push({
        path: "/check/cargoSourceDetails",
        query: { id: row.id },
      });
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
